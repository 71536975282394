export const Frontpage = () => import(/* webpackChunkName: "page/Frontpage" */ '../modules/frontpage/pages/frontpage.vue').then((m) => m.default || m);
export const Category = () => import(/* webpackChunkName: "page/Category" */ '../modules/category/pages/_id.vue').then((m) => m.default || m);
export const ArticleBase = () => import(/* webpackChunkName: "page/ArticleBase" */ '../modules/article/pages/article.vue').then((m) => m.default || m);
export const Article = () => import(/* webpackChunkName: "page/Article" */ '../modules/article/pages/_id.vue').then((m) => m.default || m);
export const ArticleComments = () => import(/* webpackChunkName: "page/ArticleComments" */ '../modules/article/pages/comments.vue').then((m) => m.default || m);
export const ArticleCommentsNapo = () =>
  import(/* webpackChunkName: "page/ArticleCommentsNapo" */ '../modules/article/pages/napo.vue').then((m) => m.default || m);
export const Tags = () => import(/* webpackChunkName: "page/Tags" */ '../modules/tags/pages/tags.vue').then((m) => m.default || m);
export const Tag = () => import(/* webpackChunkName: "page/Tag" */ '../modules/tags/pages/_id.vue').then((m) => m.default || m);
export const Authors = () => import(/* webpackChunkName: "page/Authors" */ '../modules/author/pages/authors.vue').then((m) => m.default || m);
export const Author = () => import(/* webpackChunkName: "page/Author" */ '../modules/author/pages/_id.vue').then((m) => m.default || m);
export const Search = () => import(/* webpackChunkName: "page/Search" */ '../modules/search/pages/search.vue').then((m) => m.default || m);
export const Custom = () => import(/* webpackChunkName: "page/custom" */ '../modules/custom-page/pages/custom.vue').then((m) => m.default || m);
export const Client = () => import(/* webpackChunkName: "page/custom" */ '../modules/customer/pages/client.vue').then((m) => m.default || m);
export const MyDelfi = () => import(/* webpackChunkName: "page/custom" */ '../modules/customer/pages/myDelfi.vue').then((m) => m.default || m);
export const Latest = () => import(/* webpackChunkName: "page/latest" */ '../modules/list/pages/latest.vue').then((m) => m.default || m);
export const Popular = () => import(/* webpackChunkName: "page/popular" */ '../modules/list/pages/popular.vue').then((m) => m.default || m);
export const Bookmarks = () => import(/* webpackChunkName: "page/popular" */ '../modules/customer/pages/bookmarks.vue').then((m) => m.default || m);
export const History = () => import(/* webpackChunkName: "page/popular" */ '../modules/customer/pages/history.vue').then((m) => m.default || m);
export const Weather = () => import(/* webpackChunkName: "page/weather/_id.vue" */ '../modules/weather/pages/_id.vue').then((m) => m.default || m);

export const PodcastsFrontpage = () =>
  import(/* webpackChunkName: "page/podcast/Frontpage.vue" */ '../modules/podcast/pages/frontpage.vue').then((m) => m.default || m);
export const PodcastsCategory = () =>
  import(/* webpackChunkName: "page/podcast/Category.vue" */ '../modules/podcast/pages/category.vue').then((m) => m.default || m);
export const Podcast = () => import(/* webpackChunkName: "page/podcast/Podcast.vue" */ '../modules/podcast/pages/_id.vue').then((m) => m.default || m);

export const routerComponents = {
  Frontpage,
  Category,
  ArticleBase,
  Article,
  ArticleComments,
  ArticleCommentsNapo,
  Tags,
  Tag,
  Authors,
  Author,
  Search,
  Custom,
  Client,
  MyDelfi,
  Latest,
  Popular,
  Bookmarks,
  History,
  Weather,
  PodcastsFrontpage,
  PodcastsCategory,
  Podcast,
};
